import * as numeral from 'numeral';
import StringHelper from './string-helper';

const NumberHelper = {
  currencySymbol: 'R$',
  currencyCode: 'R$',
  fromCurrencyRate: 1,
  toCurrencyRate: 1,

  toFloat: value => {
    let amount = parseFloat(value);

    // eslint-disable-next-line no-restricted-globals
    if (isNaN(amount)) {
      amount = 0;
    }

    return amount;
  },

  toInt: value => {
    let amount = +value;

    // eslint-disable-next-line no-restricted-globals
    if (isNaN(amount)) {
      amount = 0;
    }

    return amount;
  },

  currencyConvert: amount => {
    if (NumberHelper.fromCurrencyRate === NumberHelper.toCurrencyRate) {
      return amount;
    }

    if (StringHelper.isEmpty(NumberHelper.toCurrencyRate)) {
      return amount;
    }

    return (
      NumberHelper.toFloat(amount) * NumberHelper.toCurrencyRate +
      0.001
    ).toFixed(2);
  },

  currencyFormatted: amount => {
    // noinspection JSUnresolvedFunction
    let realAmount = numeral(NumberHelper.currencyConvert(amount)).format(
      '0,0.00'
    );

    if (NumberHelper.currencyCode === 'BRL') {
      realAmount = realAmount.replace('.', '-');
      realAmount = realAmount.replace(',', '.');
      realAmount = realAmount.replace('-', ',');
    }

    return `${NumberHelper.currencySymbol} ${realAmount}`;
  },

  getUniqueId: (length = 32) => {
    let ID = "";
    let characters = "abcdefghijklmnopqrstuvxzwy0123456789";
    for (var i = 0; i < length; i++) {
      ID += characters.charAt(Math.floor(Math.random() * 36));
    }
    return ID;
  }

};

export default NumberHelper;
