import {
  setCheckoutSummary,
  setGuest,
  setProduct,
  setReservationCleanup,
} from './actions';
import ObjectHelper from '../../helpers/object-helper';
import { http } from '../../helpers/http';
import * as ReservationServices from '../../services/Reservation';
import ToasterHelper from '../../helpers/toaster-helper';
import * as ApplicationActions from '../application/actions';
import * as AvailabilityActions from '../availability/actions';
import * as ProductsActions from '../products/actions';
import * as TravelActions from '../travel/actions';
import UrlHelper from '../../helpers/url-helper';
import DatalayerHelper from '../../helpers/datalayer-helper';
import LangHelper from '../../helpers/lang-helper';

export const addGuest = (
  id,
  typeId,
  salesBaseIndex,
  salesBase,
  salesBasePrice,
  salesBaseOriginalPrice,
  totalGuests,
  totalNights,
  cleaningTax,
  ratePlanRealId,
  roomTypeRealId,
  ratePlanBoardType
) => {
  return dispatch => {
    dispatch(
      setGuest(
        id,
        typeId,
        salesBaseIndex,
        salesBase,
        salesBasePrice,
        salesBaseOriginalPrice,
        totalGuests,
        totalNights,
        cleaningTax,
        ratePlanRealId,
        roomTypeRealId,
        ratePlanBoardType
      )
    );
    dispatch(setCheckoutSummary(totalNights));
  };
};

export const updateProduct = (id, total) => {
  return (dispatch, getState) => {
    const { availability } = getState();

    dispatch(setProduct(id, total));
    dispatch(setCheckoutSummary(availability.totalNights));
  };
};

export const sendReservation = (
  reservation,
  availabilityRooms,
  travel,
  history,
  t
) => {
  return async (dispatch, getState) => {
    const {
      application: {
        property: { uid, subpropertyUid },
        i18n: { currentLanguage },
      },
    } = getState();

    const payload = {
      uid: localStorage.getItem('uid'),
      name: '',
      surname: '',
      birth_date: '',
      document: '',
      email: '',
      phone_number: '',
      country: '',
      notes: '',
      payment_method: '',
      cc_name: '',
      cc_number: '',
      cc_expiration_date: '',
      cc_cvc: '',
      cc_type: null,
      cc_installments: 1,
      subtotal: '',
      total: '',
      arrival: '',
      departure: '',
      total_additional_tax: null,
      total_discount_rate: 0,
      total_discount_real_amount: 0,
      coupon_code: '',
      reserve: [],
      diaries: [],
      original_diaries: [],
      products: [],
      total_adults: 0,
      total_children: 0,
      children: [],
      cleaningTax: 0,
      original_referrer: null,
      original_source: null,
      price_variators: null,
    };

    const {
      identification,
      payment,
      arrivalDate,
      departureDate,
      couponCode,
      products,
      rooms,
      additionalTaxAmount,
      percentageDiscount,
      valueDiscount,
      totalAmount,
      cleaningTax,
      priceVariators,
    } = reservation;

    const { totalAdults, totalChildren, children } = travel;

    let total = 0;
    let subtotal = 0;

    // region Generic Data
    payload.name = identification.name;
    payload.surname = identification.surname;
    payload.birth_date = identification.birthDate;
    payload.document = identification.document;
    payload.email = identification.email;
    payload.phone_number = identification.phoneNumber;
    payload.country = identification.country;
    payload.notes = identification.notes;
    payload.arrival = arrivalDate;
    payload.departure = departureDate;
    payload.coupon_code = couponCode;
    payload.total_discount_rate = percentageDiscount;
    payload.total_discount_real_amount = valueDiscount;
    payload.total_additional_tax = additionalTaxAmount;
    payload.total = totalAmount;
    payload.cleaning_tax = cleaningTax;
    // endregion

    // region Generic Data
    payload.name = identification.name;
    payload.surname = identification.surname;
    payload.document = identification.document;
    payload.birthDate = identification.birthDate;
    payload.email = identification.email;
    payload.country = identification.country;
    payload.notes = identification.notes;
    payload.arrival = arrivalDate;
    payload.departure = departureDate;
    payload.coupon_code = couponCode;
    // endregion

    // region Guests
    payload.total_adults = totalAdults;
    payload.total_children = totalChildren;
    payload.children = children;
    // endregion

    // region Rooms
    const filteredRooms = rooms.filter(room => room.guests.length > 0);
    const requestedRooms = {};
    const internalRooms = [];

    if (filteredRooms.length > 0) {
      filteredRooms.forEach(room => {
        room.guests.forEach(guest => {

          const ruid = `${room.id}_${guest.salesBase}`;

          if (!ObjectHelper.isObject(requestedRooms[ruid])) {
            requestedRooms[ruid] = {
              base: guest.salesBase,
              units: 0,
              price: 0,
              originalPrice: 0,
              description: null,
              ratePlanBoardType: guest.ratePlanBoardType,
              adults: 0,
              children: 0,
              childrenAdditional: 0,
              childrenRegular: 0,
            };
          }

          const price = guest.salesBasePrice;
          const originalPrice = guest.salesBaseOriginalPrice;

          internalRooms.push({
            ruid,
            roomId: room.id,
            base: guest.salesBase,
          });

          let roomPlanDesc = room.ratePlanTitle !== '' ? room.ratePlanTitle : 'Default';
          roomPlanDesc = `${roomPlanDesc} (${t(LangHelper.getBoardTypeLangKey(guest.ratePlanBoardType))})`;
          
          requestedRooms[ruid].description = `${room.name} - ${roomPlanDesc}`;
          requestedRooms[ruid].units++;
          requestedRooms[ruid].price += price;
          requestedRooms[ruid].originalPrice += originalPrice;

          requestedRooms[ruid].adults = room.adults;
          requestedRooms[ruid].children = room.children;
          requestedRooms[ruid].childrenAdditional = room.childrenAdditional;
          requestedRooms[ruid].childrenRegular = room.childrenRegular;

          total += price;
          subtotal += originalPrice;
        });
      });
    }
    // endregion

    // region Rooms (Diaries)
    const requestedDiaries = {};

    if (internalRooms.length > 0) {
      internalRooms.forEach(room => {

        const baseRoom = availabilityRooms.find(
          availabilityRoom => +room.roomId === +availabilityRoom.id
        );

        if (ObjectHelper.isObject(baseRoom)) {
          baseRoom.hostelRates.forEach(hostelRatesDate => {
            if (!ObjectHelper.isObject(requestedDiaries[room.ruid])) {
              requestedDiaries[room.ruid] = {};
            }

            const rate = hostelRatesDate.rates.find(r => r.id === room.ruid);

            if (ObjectHelper.isObject(rate)) {
              requestedDiaries[room.ruid][hostelRatesDate.date] = rate.price;
            }
          });
        }
      });
    }
    // endregion

    // region Rooms (Original Diaries)
    const requestedOriginalDiaries = {};

    if (internalRooms.length > 0) {

      internalRooms.forEach(room => {
        const baseRoom = availabilityRooms.find(
          availabilityRoom => +room.roomId === +availabilityRoom.id
        );

        if (ObjectHelper.isObject(baseRoom)) {
          baseRoom.hostelOriginalRates.forEach(hostelRatesDate => {

            if (!ObjectHelper.isObject(requestedOriginalDiaries[room.ruid])) {
              requestedOriginalDiaries[room.ruid] = {};
            }

            const rate = hostelRatesDate.rates.find(r => r.id === room.ruid);

            if (ObjectHelper.isObject(rate)) {
              requestedOriginalDiaries[room.ruid][hostelRatesDate.date] =
                rate.price;
            }
          });
        }
      });
    }
    // endregion

    // region Products
    let requestedProducts = products.filter(product => +product.total > 0);

    if (requestedProducts.length > 0) {
      requestedProducts = requestedProducts.map(product => {
        
        const price = product.price * product.total;
        const originalPrice = price;

        total += price;
        subtotal += originalPrice;

        return {
          id: product.id,
          quantity: product.total,
          price,
          originalPrice,
        };
      });
    }

    payload.reserve = requestedRooms;
    payload.diaries = requestedDiaries;
    payload.original_diaries = requestedOriginalDiaries;
    payload.products = requestedProducts;
    // endregion

    // region Prices
    payload.total = total;
    payload.subtotal = subtotal;
    // endregion

    // region Payment
    if (payment.creditCard.use) {
      payload.payment_method = 'deposit_cc';
      payload.cc_name = payment.creditCard.name;
      payload.cc_number = payment.creditCard.number;
      payload.cc_expiration_date = payment.creditCard.validity;
      payload.cc_cvc = payment.creditCard.cvc;
      payload.cc_installments = payment.creditCard.installment;
    } else if (payment.transfer.use) {
      payload.payment_method = 'deposit_bt';
    } else if (payment.pix.use) {
      payload.payment_method = 'deposit_pix';
    }
    // endregion

    // region Source/Referrer
    payload.original_referrer = localStorage.getItem('originalReferrer');
    payload.original_source = localStorage.getItem('originalSource');
    // endregion

    // region priceVariators
    payload.price_variators = priceVariators;
    // endregion

    dispatch(ReservationServices.SERVICE_RESERVATION_NO_LOADED);

    /**
     * @property {Boolean} success
     * @property {Object} data
     */
    const [success, data] = await http.request(
      ReservationServices.SERVICE_RESERVATION_SAVE,
      {
        l: currentLanguage.toString().toLowerCase(),
        h: uid,
        s: subpropertyUid,
        bookingData: JSON.stringify(payload),
      }
    );

    if (success) {
      dispatch(
        ApplicationActions.setSuccess(
          data?.successMessage, 
          data?.tenantBookingId,
          payload.payment_method,
          data?.paymentPixInfo?.pix_qr_code,
          data?.paymentPixInfo?.pix_payment_line,
        )
      );
      if (data?.tenantBookingId) {
        DatalayerHelper.sendConversionEvent(data?.tenantBookingId, payload);
      }
      dispatch(AvailabilityActions.setFullCleanup());
      dispatch(ProductsActions.setProductsCleanup());
      dispatch(TravelActions.setTravelCleanup());
      dispatch(setReservationCleanup());
      dispatch(ReservationServices.SERVICE_RESERVATION_LOADED);
      history.push(UrlHelper.to('success'));

    } else {

      dispatch(ReservationServices.SERVICE_RESERVATION_LOADED);
      let firstError = '';
      if (data.errors && ObjectHelper.isObject(data.errors)) {
        const keys = Object.keys(data.errors);
        for (let i = 0; i < keys.length; i++) {
          firstError = data.errors[keys[i]];
          if (firstError !== '') {
            break;
          }
        }
      }

      ToasterHelper.error(firstError);

      if (data.overbooking === 1) {
        dispatch(AvailabilityActions.setFullCleanup());
        dispatch(ProductsActions.setProductsCleanup());
        dispatch(TravelActions.setTravelCleanup());
        dispatch(setReservationCleanup());
        history.push(UrlHelper.to('/'));
      }
    }
  };
};
